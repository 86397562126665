<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <div class="textCard">
          <!-- <h1>Cam kết bảo mật</h1> -->
          <!-- <div class="title-line"></div> -->
          <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import importedImg1 from "@/page/asenixcomercializadorasa/components/img/4_1.png";

export default {
  data() {
    return {
      altText: "protocolTitle",
      importedImg1: importedImg1,
      text: `De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión Privada (LFPDPPP) y su reglamento, los Lineamientos del Manifiesto de Privacidad y demás disposiciones aplicables (en conjunto, la "Ley"), se emite el presente Manifiesto de Privacidad en los siguientes términos:

Responsable de Datos Personales. Para efectos del presente Manifiesto de Privacidad,a nosotros es responsable en términos de la Ley de la recolección, divulgación, almacenamiento y uso, incluyendo el acceso, tratamiento, uso, explotación, transferencia o disposición de sus datos personales que recabe y, en general, de todos los datos personales que recabemos de nuestros clientes y usuarios.

Permisos
Con el fin de evaluar su elegibilidad y para ayudarle a acortar su juicio sobre si usted es elegible para nuestros productos financieros, toda la información será recopilada y presentada utilizando la última tecnología de seguridad (https), necesitamos algunos permisos para acceder a su teléfono móvil y puede ponerse en contacto con nuestro centro de servicio al cliente en cualquier momento para solicitar la eliminación de su información personal. No compartiremos sus datos con ningún tercero ajeno a la finalidad de la aplicación, a menos que así lo disponga el gobierno. De acuerdo con las leyes de los Estados y Google, respetaremos y mantendremos la privacidad de la información que comparta con nosotros durante la evaluación de su solicitud de préstamo.
Contactos de Emergencia

a nosotros sólo recopilará tus 2 o 5 contactos de emergencia para verificación de identidad, gestión de riesgos y controles antifraude. (Puede seleccionar la información de contacto que necesita rellenar de su libreta de direcciones, que sólo capturará los contactos seleccionados por usted en su libreta de direcciones, no todos sus contactos). Nunca compartiremos sus datos con terceros sin su permiso. Los datos se cifrarán y se cargarán y almacenarán en nuestros servidores a través de una conexión segura (https) y sólo recopilaremos estos datos con su consentimiento. Sus datos estarán estrictamente protegidos por nosotros

Ubicación
a nosotros recopila información de ubicación de su dispositivo para reducir el riesgo asociado a su cuenta y aumentar la seguridad de los datos. Todas las transacciones están protegidas por un cifrado SSL de 128 bits. Los datos se cargarán a través de una conexión segura y se almacenarán en nuestros servidores

Cámara
El acceso a la cámara nos permite escanear los documentos KYC requeridos para quea nosotros pueda autocompletar los campos pertinentes. También se utilizará para realizar su proceso de KYC por vídeo, que se utiliza para confirmar que está realizando la solicitud en persona para evitar que su información sea robada o genere fraude.
Información instalada en la aplicación.
a nosotros utiliza cookies. Las cookies son herramientas empleadas por los servidores web para almacenar y recuperar información que se almacena en el navegador utilizado por el usuario o visitante del sitio web, permitiendo guardar sus preferencias personales con el fin de proporcionarle una mejor experiencia de navegación.

Las cookies tienen una fecha de caducidad, que puede oscilar desde el tiempo que dure la sesión o visita al sitio web hasta una fecha determinada, cumplida la cual dejan de utilizarse. Las cookies utilizadas en se asocian únicamente con usuarios anónimos y sus equipos informáticos, no proporcionan referencias que permitan deducir el nombre y apellidos del usuario, no leen datos de su disco duro ni contienen en sus textos virus.

El usuario puede configurar su navegador para aceptar o rechazar automáticamente todas las cookies, o para recibir un aviso en pantalla sobre la aceptación de cada cookie, y decidir acerca de la instalación o no de una cookie en su disco duro Le recomendamos que consulte la sección de ayuda de su navegador para obtener información sobre cómo cambiar la configuración para aceptar o rechazar cookies. Aunque configure su navegador para rechazar todas las cookies o para rechazar explícitamente las cookies de, podrá seguir navegando por el sitio, con el único inconveniente de que no podrá disfrutar de las funciones del sitio que requieran la instalación de alguna cookie. En cualquier caso, podrá eliminar en cualquier momento las cookies de instaladas en su disco duro siguiendo los procedimientos indicados en la sección de ayuda de su navegador.

Le confirmamos quea nosotros no recoge datos personales por estas vías y, por tanto, estos datos no son objeto de tratamiento ni de cesión.

Es importante quea nosotros le informe de que nunca utilizaremos la información recogida por estos medios para fines personales o de cualquier otro tipo.a nosotros no puede revocar estas autorizaciones ya que esto debe modificarse desde el teléfono móvil autorizado; por lo tanto, puede revocar estas autorizaciones en cualquier momento accediendo a la configuración de su teléfono móvil.

Es importante que sepa que en cualquier momento puede ponerse en contacto con nosotros a través de los datos de contacto que le facilitamos en nuestra página web y estaremos encantados de asesorarle sobre el procedimiento para revocar el consentimiento anterior desde su dispositivo.

a nosotros le notificará la decisión adoptada en un plazo máximo de veinte (20) días hábiles desde la recepción de la correspondiente solicitud. En caso de que su solicitud sea aceptada, surtirá efectos dentro de los quince días hábiles siguientes a la fecha en que la Controladora envíe su respuesta.

Si la información proporcionada en su solicitud es incorrecta o insuficiente, o si no se acompaña de los documentos necesarios para acreditar su identidad o la representación legal correspondiente, o de cualquier otro documento necesario para ejercer sus derechos, le solicitaremos que subsane las deficiencias en un plazo de cinco días hábiles contados a partir de la fecha de recepción de su solicitud, a fin de dar trámite a la misma. En este caso, dispondrá de diez días laborables a partir del día siguiente a la fecha de recepción de la solicitud de corrección para responder a la misma. Si no responde en este plazo, la solicitud correspondiente se considerará no presentada.

Si tiene un préstamo vigente con nosotros (vencido o por vencer),a nosotros no podrá realizar ninguna corrección en su perfil. Para ello, primero tendrá que reembolsar su préstamo y, una vez reembolsado, siga el proceso descrito anteriormente. Recuerda que si no estás conforme o no cumples con nuestras reglas, puedes acudir al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para iniciar un procedimiento de protección de derechos.

Como titular, eres responsable de mantener actualizados tus datos personales en posesión dea nosotros. Por lo tanto, en todos los casos, usted garantiza y responde por la veracidad, exactitud, vigencia y autenticidad de los datos personales proporcionados y se compromete a mantenerlos actualizados y a comunicar al responsable del tratamiento cualquier modificación de los mismos.

Revocación del consentimiento.

En cualquier momento, usted podrá revocar su consentimiento para el tratamiento de sus datos personales, siempre que dicha revocación sea lícita y no suponga la imposibilidad de cumplir con las obligaciones derivadas de la relación contractual vigente entrea nosotros y usted, o en virtud de mandato legal o judicial.

Cambios en la Declaración de Privacidad. Cualquier actualización, modificación o cambio de esta Declaración de Privacidad de acuerdo con la nueva legislación y/o cambios en las políticas internas dea nosotros se publicará en el sitio web.

No obstante, cuando se produzca un cambio sustancial en el contenido de esta Declaración de Privacidad, por ejemplo, un cambio en la finalidad para la que se recogen los Datos Personales, se lo notificaremos y le solicitaremos de nuevo su consentimiento para seguir procesando sus Datos Personales.

Política de seguridad.

a nosotros protege sus datos personales utilizando tecnología de encriptación estándar del sector para garantizar que los datos enviados desde el sitio web llegan a nuestros servidores de forma segura. Tomamos medidas de seguridad para proteger sus datos personales cuando almacenamos y procesamos su información.

Ena nosotros contamos con procedimientos que definen quién puede acceder a sus datos y en qué condiciones, y la protección de su privacidad es siempre nuestra máxima prioridad. También contamos con medidas de seguridad física para evitar el acceso no autorizado a nuestros sistemas e instalaciones.
Su consentimiento

Al leer y aceptar esta Declaración de Privacidad cada vez que acceda a sus Datos de Usuario por cualquier medio, usted indica expresamente que

1.a nosotros le ha informado de esta Declaración de Privacidad

2. usted ha leído y comprende plenamente los términos y el alcance de esta Declaración de Privacidad y, por lo tanto, consiente expresamente el tratamiento de sus Datos Personales de conformidad con los términos de esta Declaración de Privacidad.

Seguridad de la información personal Valoramos todos los datos personales que recogemos, utilizamos, conservamos y almacenamos.a nosotros ha adoptado medidas de seguridad razonables, tanto técnicas como físicas, para proteger su información frente al acceso no autorizado o la pérdida, mal uso o alteración por parte de terceros. Aunque nos esforzamos por cifrar la información recogida en la Plataforma y cargarla en servidor, no podemos garantizar la seguridad absoluta de dicha información durante su transmisión o almacenamiento. Utilizaremos las medidas técnicas y organizativas adecuadas para proteger los datos de los usuarios. Las medidas que empleamos están diseñadas para proporcionar un nivel de seguridad proporcional a los riesgos asociados al tratamiento de la información personal de los Usuarios, y las mejoramos continuamente. Nuestras medidas varían, pero en general incluyen controles para restringir el acceso a los servicios o sistemas que contienen datos personales, seudonimización, cortafuegos para proteger las bases de datos, contraseñas y otras medidas técnicas. Además, aunque nos esforzamos por garantizar la integridad y seguridad de nuestras redes y sistemas, no podemos garantizar que nuestras medidas de seguridad impidan por completo que terceros "piratas informáticos" accedan ilegalmente a esta información. En el improbable caso de que se produzca un robo de datos como consecuencia de piratería no autorizada, ataques de virus o problemas técnicos, no asumimos responsabilidad alguna.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
img {
  display: block;
}
.imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1,
.img2 {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2 {
  width: 100%;
  margin: 0;
}
.img2{
  padding: 0 220px;
}

h1 {
  margin-top: 50px;
  margin-left: 490px;
  color: #3f4842;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}
.textBox {
  /* margin: 70px 0 150px; */
  margin: 30px 0 150px;
  width: 90%;
  font-size: 18px;
  color: #333333;
}
.textCard {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 32px;
  /* box-shadow: 0 2px 20.8px -5px rgba(0, 0, 0, 0.25); */
  margin: 10px 0 200px;
}

.title-line {
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0baf60;
  margin: 0 500px;
}
.img1{
  margin-top: 20px;
}
</style>